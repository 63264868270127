import * as React from 'react';
import styled from 'styled-components';
import LazyLoad from 'react-lazyload';
import { useHistory } from 'react-router';

interface ITab {
  title: string;
  ingress: string;
  slug?: string;
  href?: string;
  image: string;
  currentPage?: number;
}

export const NewsCard: React.SFC<ITab> = React.memo(
  ({ title, ingress, slug, href, image, currentPage }) => {
    const history = useHistory();
    const [loaded, setLoaded] = React.useState(false);
    const [missingImage, setMissingImage] = React.useState(false);

    React.useEffect(() => {
      if (loaded) {
        setLoaded(false);
      }
    }, [currentPage]);

    return (
      <StyledNewsItem
        className="property-item"
        onClick={() => {
          if (slug) {
            history.push(slug);
          } else if (href) {
            window.location.href = href;
          }
        }}
      >
        <StyledNewsImageWrapper>
          <StyledNewsImage loaded={loaded}>
            <LazyLoad height={250} throttle={100} offset={100}>
              <>
                {loaded && !missingImage ? (
                  <img src={image} alt="bilde" />
                ) : null}

                <img
                  className="lazy-image"
                  src={image}
                  onError={() => {
                    setMissingImage(true);
                  }}
                  onLoad={() => {
                    setLoaded(true);
                  }}
                />
              </>
            </LazyLoad>
          </StyledNewsImage>
        </StyledNewsImageWrapper>
        <StyledNewsContent>
          {title ? <p className="news-item-heading">{title}</p> : null}
          {ingress ? <p className="news-item-ingress">{ingress}</p> : null}
          <span className="news-item-readmore">Les mer</span>
        </StyledNewsContent>
      </StyledNewsItem>
    );
  },
  (prevProps, nextProps) => {
    if (prevProps.slug === nextProps.slug) {
      return true;
    }
    return false;
  }
);

const StyledNewsContent = styled.div`
  padding: 10px 20px 20px 20px;

  .news-item-heading {
    font-weight: 600;
    color: ${(props) => props.theme.colors.primary};
  }
  .news-item-ingress {
  }

  span {
    color: ${(props) => props.theme.colors.primary};
  }
`;

const StyledNewsImageWrapper: any = styled.div`
  position: relative;
  width: 100%;
  height: 250px;
  background: rgb(15, 15, 15);

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;

    &.lazy-image {
      position: absolute;
      width: 1px;
      height: 1px;
      z-index: -1;
    }
  }
`;

const StyledNewsImage: any = styled.div`
  transition: opacity 0.2s ease-in-out;
  position: relative;
  width: 100%;
  height: 250px;
  opacity: ${(props: any) => (props.loaded ? 1 : 0)};

  img {
    object-fit: cover;
    width: 100%;
    max-height: 100%;
  }
`;

const StyledNewsItem = styled.div`
  transition: background 0.2s ease-in-out;
  display: flex;
  grid-column: span 1;
  flex-flow: column;
  margin-bottom: 14px;
  background: #1c1c1c;
  overflow: hidden;

  .county {
    word-break: break-all;
  }

  &:hover {
    cursor: pointer;
    background: #e5c88f;

    .news-item-heading {
      color: black;
    }
    .news-item-ingress {
      color: black;
    }
    .news-item-readmore {
      color: black;
    }
  }
`;

export const NewsListWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-flow: row wrap;
  width: 100%;

  &::after {
    content: '';
    flex: auto;
  }

  .property-item {
    width: calc(33.3333% - 10px);

    &:nth-last-child(1) {
      margin-left: 15px;
    }
  }

  @supports (display: grid) {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 15px;

    .property-item {
      width: 100%;
      &:nth-last-child(1) {
        margin-left: auto;
      }
    }
  }

  @media screen and (max-width: 580px) {
    .property-item {
      width: 100%;
      &:nth-last-child(1) {
        margin-left: auto;
      }
    }
    @supports (display: grid) {
      grid-template-columns: 1fr;
    }
  }

  @media screen and (min-width: 580px) and (max-width: 960px) {
    .property-item {
      width: calc(50% - 10px);
      &:nth-last-child(1) {
        margin-left: 15px;
      }
    }
    @supports (display: grid) {
      grid-template-columns: 1fr 1fr;
      .property-item {
        width: 100%;
        &:nth-of-type(even) {
          margin-left: 0;
        }
        &:nth-last-child(1) {
          margin-left: auto;
        }
      }
    }
  }
`;
