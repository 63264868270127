import * as React from 'react';
import styled from 'styled-components';
import { Container } from '../../../layout/Container';
import { NewsCard } from '../../../ui/cards/News';
import { Heading } from '../../../ui/text/Heading';
import { Paragraph } from '../../../ui/text/Paragraph';

const InformasjonBg: React.FC = () => {
  return (
    <PageStyles>
      <Container style={{ paddingTop: '100px' }}>
        <TopImage src="https://reeltimeprod-ahebbuatfggte7f2.z01.azurefd.net/privatmegleren/top3.jpg" />
        <Heading tag="h1" center>
          Kun det beste på dine vegne
        </Heading>
        <Paragraph center>
          Det kan være vanskelig å huske alt som ble gjennomgått i møte med vår
          megler. For å hjelpe deg har vi derfor oppsummert deler av
          informasjonen – og kanskje lagt til noe.
        </Paragraph>

        <Heading tag="h3" center>
          Tilrettelegging
        </Heading>
        <Paragraph center>
          Strategi: Veien til en høyest mulig pris starter lenge før eiendommen
          annonseres. Vi i PrivatMegleren tilpasser salget til hver enkelt
          eiendom, for å oppnå det beste resultatet. Vår skreddersøm er en
          viktig del av leveransen du mottar, vi skal finne og hente ut
          eiendommens fulle potensiale.
        </Paragraph>

        <Paragraph center>
          Fotograf og stylist: Kun de beste innen sitt fagfelt finner veien
          gjennom vårt nåløye. Våre samarbeidspartnere utfører sine oppgaver i
          henhold til streng instruks, for å sikre en gjennomprøvd leveranse av
          høy kvalitet hver eneste gang. Våre meglere vil gi råd til både deg
          som kunde og våre samarbeidspartnere, slik at boligen vises fra sin
          beste side når den skal selges.
        </Paragraph>

        <Paragraph center>
          Interessentregister: Som et av Norges største meglerforetak, besitter
          vi en betydelig mengde interessenter i vår database. Vi har en aktiv
          tilnærming til vårt kunderegister og vil passe på å informere alle
          interessenter når vi starter markedsføringen av din eiendom.
        </Paragraph>

        <Heading tag="h3" center>
          Markedsføring
        </Heading>

        <Paragraph center>
          Vår jobb er å passe på at du ikke går glipp av en eneste budgiver. Vår
          megler vil tilpasse boligens annonsering til ditt boligsalg og det
          aktuelle markedet.
        </Paragraph>

        <Paragraph center>
          Finn.no: Aktive interessenter utgjør rundt 8% av markedet. Dette er
          mulige kjøpere som aktivt leter etter bolig på finn.no. Som vår kunde
          er du sikret den beste annonseringen tilgjengelig på denne portalen.
          Våre annonser er 150% større enn standardannonsen og inneholder blant
          annet tilleggsproduktet, Ukens Bolig, som gir ekstra god annonsering
          under uken før første visning.
        </Paragraph>

        <Paragraph center>
          Ukens Nyhet: I snitt sjekker vi nyhetsbilde på mobiltelefon 2-5 ganger
          i døgnet. Sammen med de nettaviser vi leser hyppigst har vi skapt en
          unik digital annonseløsning. Løsningen plasserer din bolig i
          nyhetsbilde gjennom målrettede enkeltannonser på bt.no, e24.no og
          vg.no.
        </Paragraph>

        <Paragraph center>
          Presisjon: I PrivatMegleren har vi enerett på Presisjon, et smart og
          avansert annonseverktøy for eiendom.
        </Paragraph>

        <Paragraph center>
          En undersøkelse gjennomført i november 2023 av Respons Analyse, viser
          at andelen som oppgir å være aktivt på eiendomssøk er 8%, mens 33% er
          passive. Målet med Presisjon er at du ikke skal gå glipp av mulige
          budgivere som ikke aktivt leter etter akkurat din bolig.
        </Paragraph>

        <Paragraph center>
          Målinger viser at det i snitt kommer 52% flere på PrivatMeglerens
          egnes visninger som markedsføres med Presisjon, dette kan ha stor
          betydning for salgssummen*. Analyser viser i tillegg at Presisjon gir
          syv ganger flere klikk på annonsen enn det som er vanlig i bransjen,
          på denne måten øker Presisjon potensialet for flere budgivere på din
          bolig.
        </Paragraph>

        <Paragraph center>
          Ifølge forskning gir én ekstra budgiver i snitt 3,5% høyere salgssum*.
        </Paragraph>

        <Paragraph center>
          Velger du Presisjon har du nabolags-DM inkludert i annonseringen. Vi
          varsler naboer fordi på bakgrunn av tall fra SSB fra 2022, som viser
          at de fleste nordmenn flytter over korte avstander.
        </Paragraph>

        <Paragraph center>
          Avis: Du står fritt til å annonsere i avis de dager du måtte ønske,
          men målinger avdekker derimot at lørdag er den beste dagen. Foruten at
          opplag er på topp i helgene oppgir flertallet at de foretrekker å lese
          avisen denne dagen.
        </Paragraph>

        <Paragraph center>
          Annonseringen skal være tilpasset boligsalget. For enkelte eiendommer
          vil vi anbefale å skreddersy boligannonsen og fortelle en historie om
          eiendommen.
        </Paragraph>

        <Heading tag="h3" center>
          Visning, budrunde og etterarbeid
        </Heading>

        <Paragraph center>
          Gjennomføring: Alle våre meglere følger den samme metodikken når det
          kommer til behandling av interessenter og gjennomføring av visning.
          Presentasjonen av eiendommen og riktig oppfølging er sentralt for å
          gjøre interessenter til budgivere. Vi gjør det lille ekstra hele
          veien, slik at din visning skiller seg ut fra andre visninger.
        </Paragraph>

        <Paragraph center>
          Romplakater: Vi sørger for at de som kommer på visning mottar riktig
          informasjon på rett sted til rett tid. Slik styrker vi den gode
          visningsopplevelsen – og sørger for at ingen mulige budgivere går
          glipp av viktig informasjon.
        </Paragraph>

        <Paragraph center>
          Oppfølging og budrunde: Våre rutiner sikrer at alle interessenter får
          den samme gode oppfølgingen. Vi gir svar på alle spørsmål og setter
          interessenter i stand til å by ved å følge opp både før og etter
          visning. Vi er blant annet behjelpelig med å skaffe interessenter
          finansiering før budrunden starter, de ganger det er behov.
          PrivatMegleren har lagt ned betydelig arbeid i å avklare hvordan våre
          meglere kan gjennomføre budrunden på best mulig måte. Dette for å
          sikre at vi oppnår best mulig salgssum for deg.
        </Paragraph>

        <Paragraph center>
          Etterarbeid: Jobben er ikke ferdig før eiendommen er overtatt og
          oppgjøret gjennomført. Vår megler følger deg hele veien og deltar også
          ved overtagelse.
        </Paragraph>

        <Paragraph center>
          Dersom du leser dette, har du allerede mottatt tilbud fra en av våre
          meglere. PrivatMeglerens mål er å tilby markedets beste verktøykasse.
          Vi er opptatt av å kunne vise til faktiske målinger og har derfor gått
          vitenskapelig til verks for at du skal være trygg på at tilbudet du
          har fått er det som vil gjøre at du sitter igjen med det beste salget
          av din bolig.
        </Paragraph>

        <Paragraph center>
          Er du usikker på noe i tilbudet du har mottatt, ta dette opp med
          megler.
        </Paragraph>

        <Paragraph center>
          Vi ser frem til et godt samarbeid etter nærmere avtale.
        </Paragraph>

        <Heading tag="h3" center>
          Andre tjeneste å vurdere
        </Heading>

        <Paragraph center>
          Kjøpsmegling: vi er ikke bare en god støttespiller i salgsprosessen –
          visste du at vi også kan hjelpe deg når du skal kjøpe bolig?
        </Paragraph>

        <Paragraph center>
          Med tjenesten KJØP har du megler med deg i hvert trekk i forbindelse
          med kjøp av eiendom. Vi kartlegger behovene dine nøye og matcher med
          både ulike markeder og våre egne nettverk for å finne det som du vil
          være interessert i. Vi hjelper med gjennomgang og vurdering av
          salgsoppgaver og tilstandsrapporter, vurdering av prisantydninger til
          taktisk rådgivning gjennom budrunden.
        </Paragraph>

        <Paragraph center>
          Du kan lese mer om hvordan vi kan bidra til at ditt kjøp av eiendom
          blir en god opplevelse{' '}
          <a href="https://privatmegleren.no/kjop">her.</a>
        </Paragraph>

        <Paragraph center>
          PrivatMegleren Boligbytte: Vi kan også bistå i flytteprosessen. Sammen
          med håndplukkede samarbeidspartnere kan vi hjelpe til med alt fra
          vask, styling og klargjøring av eiendommen før visning, til pakking,
          mellomlagring, flytting og overføringer av forskjellige avtaler.
        </Paragraph>

        <Paragraph center>
          Så enkelt skal vet være å flytte! Du kan lese mer{' '}
          <a href="https://privatmegleren.no/boligbytte">her.</a>
        </Paragraph>
        <Paragraph center>Du kan lese mer her.</Paragraph>

        <NewsGrid>
          <NewsCard
            title="Den viktigste ingrediensen i et vellykket eiendomssalg"
            ingress="
          Selv med noen av markedets beste salgsverktøy, er fremdeles megleren den viktigste ingrediensen i et vellykket eiendomssalg.
      "
            image="https://cdn.reeltime.no/pm_assets/kampanjer/informasjon_bg/4.png"
            href="https://meglervalget.no/den-viktigste-ingrediensen-i-et-vellykket-boligsalg"
          />

          <NewsCard
            title="Ekte trykksverte falmer ikke -derfor er eiendomsannonsering i avisen gull verdt."
            ingress="
          Ekte trykksverte falmer ikke -derfor er eiendomsannonsering i avisen gull verdt.
      "
            image="https://cdn.reeltime.no/pm_assets/kampanjer/informasjon_bg/5.jpeg"
            href="https://meglervalget.no/trykksverte-falmer-ikke"
          />

          <NewsCard
            title="Ekte trykksverte falmer ikke -derfor er eiendomsannonsering i avisen gull verdt."
            ingress="

          – Vi er så trygge på vår leveranse at vi like greit garanterer at vi
          klarer å selge eiendommen din til en pris du er fornøyd med. Klarer vi
          det ikke, betaler du ingenting, forteller Eivind Markhus,
          daglig leder i PrivatMegleren Nord.
        
      "
            image="https://cdn.reeltime.no/pm_assets/kampanjer/informasjon_bg/6.jpeg"
            href="https://meglervalget.no/garanterer-a-selge-boligen"
          />
        </NewsGrid>
      </Container>
    </PageStyles>
  );
};

const PageStyles = styled.div`
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-top: 40px;
  }
`;

const TopImage = styled.img`
  width: 100%;
  margin-bottom: 50px;
`;
const NewsGrid = styled.div`
  margin-top: 50px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  width: 100%;

  .property-item {
    margin-bottom: 0px;
  }
  @media all and (max-width: 649px) {
    grid-template-columns: 1fr;
  }

  @media all and (min-width: 650px) and (max-width: 1000px) {
    grid-template-columns: 1fr 1fr;
  }
`;
export default InformasjonBg;
